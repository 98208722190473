import * as HIcons from '@heroicons/react/24/outline';
import cx from 'classnames';
import iconClasses from './Icon.module.scss';
type IconColor = 'black' | 'white' | 'warning' | 'error';

interface IconProps {
  icon: any;
  size: any;
  className?: string;
  color: IconColor;
}
export default function Icon({ icon, size, color, className }: IconProps) {
  const cs = cx(
    iconClasses.icon,
    {
      [iconClasses[`color-${color}`]]: color !== undefined,
    },
    className
  );
  const { ...icons } = HIcons;
  // @ts-ignore
  const TheIcon: JSX.Element = icons[icon];

  return (
    <>
      {/* @ts-ignore */}
      <TheIcon icon={icon} className={cs} />
    </>
  );
}
