import styles from './GameLayout.module.scss';

const GameLayout = ({ children }: any) => {
  return (
    <div className={styles.main}>
      <div className={styles.toolbar}>
        <img alt="logo-air-europa" src="/img/logo_aireuropa.svg" />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default GameLayout;
