import Title from '../../../_common/components/Title/Title';
import styles from './LoadingGame.module.scss';
interface LoadingGameProps {
  counter: number | null;
}
const LoadingGame = ({ counter }: LoadingGameProps) => {
  return (
    <>
      <Title type="h3" color="white">
        ¡Prepárate!
      </Title>
      <div className={styles.container}>
        <Title type="h1" color="ae-coral">
          Empezamos
        </Title>
        <div className={styles.containerCount} color="white">
          <Title type="h1" color="white" className={styles.count}>
            {`${counter}`}
          </Title>
        </div>
      </div>
    </>
  );
};

export default LoadingGame;
