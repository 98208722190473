import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl } from 'app/api/index';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl('/'),
    // prepareHeaders: tokenInterceptor,
    // prepareHeaders: { tokenInterceptor, rtkQueryErrorLogger },
  }),
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
    }),
    // update: builder.mutation({
    //   query: (user) => ({
    //     url: 'user',
    //     method: 'PUT',
    //     body: user,
    //   }),
    // }),
    // user: builder.query({
    //   query: (token) => ({
    //     url: 'user',
    //     method: 'GET',
    //     body: token,
    //   }),
    // }),
    // remind: builder.mutation({
    //   query: (email) => ({
    //     url: 'remind',
    //     method: 'POST',
    //     body: { email },
    //   }),
    // }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  // useSignupMutation,
  // useUpdateMutation,
  // useRemindMutation,
} = authApi;
