import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import linkToClasses from './LinkTo.module.scss';

interface LinkToProps extends ButtonProps {
  href: string;
  textDecorationNone?: boolean;
  children?: any;
  button?: boolean;
  selected?: boolean;
  isExternalLink?: boolean;
}
const LinkTo = ({
  href,
  variant = 'normal',
  color = 'white',
  prefix,
  suffix,
  selected = false,
  button = false,
  textDecorationNone = false,
  className,
  children,
  onClick,
  fullWidth,
  isExternalLink = false,
}: LinkToProps) => {
  const classes = !button
    ? cx(
        linkToClasses.link,
        {
          [linkToClasses.decorationNone]: textDecorationNone === true,
          [linkToClasses.button]: button,
          [linkToClasses.selected]: selected,
        },
        className
      )
    : cx(className);

  const navigate = useNavigate();

  const handleClick = (event: React.SyntheticEvent) => {
    if (href) {
      navigate(href);
    }
    if (onClick) onClick(event);
  };

  return button ? (
    <Button
      variant={variant}
      pointer
      color={color}
      prefix={prefix}
      suffix={suffix}
      className={classes}
      onClick={event => handleClick(event)}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  ) : isExternalLink ? (
    <a href={href} target="_blank" rel="noreferrer" className={classes}>
      {children}
    </a>
  ) : (
    <NavLink
      to={href}
      className={({ isActive }) =>
        isActive ? `${classes} ${linkToClasses.selected}` : classes
      }
      onClick={onClick}
    >
      {prefix}
      {children}
      {suffix}
    </NavLink>
  );
};
export default LinkTo;
