import React from 'react';
import cx from 'classnames';
import titleClasses from './Title.module.scss';
type TitleColor =
  | 'ae-blue'
  | 'ae-green'
  | 'ae-coral'
  | 'ae-silver'
  | 'ae-gold'
  | 'warning'
  | 'error'
  | 'white'
  | 'black';

type TitleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

interface TitleProps {
  className?: string;
  type: TitleType;
  color: TitleColor;
  children: string;
}

const Title = (props: TitleProps) => {
  const { type = 'h1', color, className, children } = props;
  const cs = cx(
    titleClasses.title,
    { [titleClasses[`color-${color}`]]: color !== undefined },
    className
  );
  // TODO: overwrite with custom scss modules classes
  return React.createElement(type, { className: cs }, children);
};

export default Title;
