import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import Title from '../../../_common/components/Title/Title';
import { IUserResult } from '../../../_common/models';
import { ResultContainer } from '../ResultItem/ResultItem';
import { logout } from '../../slices/auth';
import { resetGame } from '../../slices/game';
import styles from './ResultGame.module.scss';
import Button from '../../../_common/components/Button/Button';

interface ResultGameProps {
  result: IUserResult;
  questionsCount: number;
}
const ResultFinal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleFinish = () => {
    dispatch(resetGame());
    dispatch(logout());
    navigate('/');
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handleFinish();
    }, 20000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={styles.final}>
      <Title color="ae-coral" type="h5" className={styles.title}>
        Muchas gracias por participar
      </Title>
      <Title color="white" type="h4" className={styles.text}>
        Te enviaremos un correo electrónico cuando acabe FITUR para decirte si has ganado
        Millas Suma.
      </Title>
      <Button
              variant="raised"
              color="coral"
              onClick={() => {handleFinish();}}
              fullWidth
            >
              Siguiente jugador
            </Button>
    </div>
  );
};

const ResultGame = ({ result, questionsCount }: ResultGameProps) => {
  const [showResults, setShowResults] = useState<boolean>(true);

  useEffect(() => {
    const timer: ReturnType<typeof setTimeout> = setTimeout(
      () => setShowResults(false),
      7000
    );
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showResults ? (
        <div className={styles.resultGame}>
          <div>
            <Title type="h3" color="white" className={styles.lineDown}>
              ¡Se acabó!
            </Title>
            <Title type="h5" color="ae-coral" className={styles.lineDown2}>
              Puntuación
            </Title>
          </div>
          {result && <ResultContainer className={styles.lineDown2} data={result} questionsCount={questionsCount} />}
        </div>
      ) : (
        <ResultFinal />
      )}
    </>
  );
};

export default ResultGame;
