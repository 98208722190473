import cn from "classnames";
import moment from "moment";
import Icon from "../../../_common/components/Icon/Icon";
import Title from "../../../_common/components/Title/Title";
import { IUser, IUserRanking, IUserResult } from "../../../_common/models";
import { useAuth } from "../../hooks/useAuth";

import styles from "./ResultItem.module.scss";

interface ResultContainerProps {
  data: IUserResult | IUserRanking[];
  userProps?: IUser;
  className?: string;
  questionsCount: number;
}
interface ResultItemProps {
  result: IUserRanking;
  questionsCount: number;
}
const ResultItem = ({ result, questionsCount }: ResultItemProps) => {
  return (
    <div className={styles.bg}>
      <div className={styles.iconsContainer}>
        <div className={styles.item}>
          <Icon color="black" icon={"CheckBadgeIcon"} size={22} />
          <Title type="p" color="black">
            {`${result.points}/${questionsCount}`}
          </Title>
        </div>
        <div className={styles.item}>
          <Icon color="black" icon={"ClockIcon"} size={22} />
          <Title type="p" color="black">
            {result.time > 59
              ? moment.utc(result.time * 1000).format("mm:ss.SSS")
              : `${moment.utc(result.time * 1000).format("ss.SSS")} s`}
          </Title>
        </div>
      </div>
      <div>
        {result.created_at && (
          <Title type="p" color="black">
            {moment(result.created_at as any)
              .locale("es-ES")
              .format("DD/MM/yyyy")}
          </Title>
        )}
      </div>
    </div>
  );
};
export const ResultContainer = ({
  className,
  data,
  userProps,
  questionsCount,
}: ResultContainerProps) => {
  const { user } = useAuth();

  return (
    <div className={cn(styles.resultView, className)}>
      <div className={styles.resultContainer}>
        <Title type="h1" color="white">
          Tus resultados
        </Title>
        <Title type="h5" color="black">
          {user
            ? `${user?.name} ${user?.surname}`
            : `${userProps?.name} ${userProps?.surname}`}
        </Title>
        {Array.isArray(data) ? (
          data.map((el) => (
            <ResultItem
              result={el}
              questionsCount={questionsCount}
              key={`${el.created_at}-${el.time}`}
            />
          ))
        ) : (
          <ResultItem result={data} questionsCount={questionsCount} />
        )}
      </div>
    </div>
  );
};

export default ResultItem;
