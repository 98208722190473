import { useState, useEffect, ReactNode } from 'react';
import cx from 'classnames';
import inputClasses from './Input.module.scss';

type InputType = 'text' | 'password' | 'email' | 'number' | 'date';
interface InputProps {
  type: InputType;
  value: string;
  placeholder: string;
  name: string;
  onChange: any;
  className?: string;
  fullWidth: boolean;
  pattern?: string;
}
interface InputLabelProps {
  name: string;
  children: string;
}
interface InputErrorProps {
  children: string;
}
interface InputContainerProps {
  className?: string;
  classNameContainer?: string;
  children: any;
  prefix?: ReactNode;
  suffix?: ReactNode;
  name: string;
  placeholder?: string;
  error?: string;
  inputDisabled?: boolean;
  containerFocus?: any; //func,
}
const Input = (props: InputProps) => {
  const { name, type, value, placeholder, onChange, className, fullWidth, ...other } =
    props;
  const [currentValue, setCurrentValue] = useState(value || '');
  const cs = cx(
    inputClasses.input,
    {
      [inputClasses[`full-width`]]: fullWidth,
    },
    className
  );

  const handleChange = (event: any) => {
    setCurrentValue(event.target.value);
    if (onChange) {
      onChange(event.target.value, event);
    }
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <input
      className={cs}
      id={name}
      name={name}
      type={type}
      value={currentValue || ''}
      onChange={evt => handleChange(evt)}
      placeholder={placeholder}
      {...other}
    />
  );
};

export const InputLabel = ({ name, children }: InputLabelProps) => {
  const cn = cx(inputClasses.label);
  return (
    <label htmlFor={name} className={cn}>
      {children}
    </label>
  );
};

export const InputError = ({ children }: InputErrorProps) => {
  const cn = cx(inputClasses.error);

  return <span className={cn}>{children}</span>;
};

export const InputContainer = ({
  children,
  placeholder,
  name,
  className,
  classNameContainer,
  prefix,
  suffix,
  error,
  inputDisabled,
  containerFocus,
}: InputContainerProps) => {
  const cn = cx(
    inputClasses.field,
    error && inputClasses.fieldError,
    prefix || (suffix && (inputClasses.prefix as any)),
    prefix && suffix && inputClasses.prefixAndSuffix,
    inputDisabled && inputClasses.isDisabled,
    className
  );

  const ccn = cx(inputClasses.container, classNameContainer);

  return (
    <div className={ccn} onFocus={containerFocus}>
      {/* <InputLabel name={name}>{placeholder}</InputLabel> */}
      <div className={cn}>
        {prefix}
        {children}
        {suffix}
      </div>
      {error && <InputError>{error}</InputError>}
    </div>
  );
};

export default Input;
