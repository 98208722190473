/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/slices/store';
import { IAnswerResult, IQuestion, IUserResult } from '../../_common/models';
type GameState = {
  counter: number | null;
  questionCountdown: number;
  stopQuestionCountdown: boolean;
  data: IQuestion[] | undefined | null;
  currentStep: number;
  currentQuestion: number;
  initialDate: Date | undefined;
  question: IQuestion | null;
  result: IAnswerResult[];
  userResult: IUserResult | undefined;
};

const initialState: GameState = {
  counter: null,
  questionCountdown: 0,
  stopQuestionCountdown: false,
  data: null,
  currentStep: 0,
  currentQuestion: 0,
  initialDate: undefined,
  question: null,
  result: [],
  userResult: undefined,
};
const slice = createSlice({
  name: 'game',
  initialState: initialState,
  reducers: {
    setCounter: (state, { payload: num }) => {
      state.counter = num;
    },
    setQuestionCountDown: (state, { payload: num }) => {
      state.questionCountdown = num;
    },
    setStopQuestionCountdown: (state, { payload: bool }) => {
      state.stopQuestionCountdown = bool;
    },
    setGame: (state, { payload: data }) => {
      state.data = data;
    },
    setCurrentStep: (state, { payload: data }) => {
      state.currentStep = data;
    },
    setCurrentQuestion: (state, { payload: data }) => {
      state.currentQuestion = data;
    },
    setQuestion: (state, { payload: data }) => {
      state.question = data;
    },
    setResult: (state, { payload: res }) => {
      state.result = [...state.result, res];
    },
    setResetDataGame: state => {
      state.data = null;
    },
    setInitialDate: (state, { payload: date }) => {
      state.initialDate = date;
    },
    setUserResult: (state, { payload: userResult }) => {
      state.userResult = userResult;
    },
    resetGame: () => {
      return initialState;
    },
  },
});

export const {
  setCounter,
  setQuestionCountDown,
  setStopQuestionCountdown,
  setGame,
  setCurrentStep,
  setCurrentQuestion,
  setQuestion,
  setResult,
  setResetDataGame,
  setInitialDate,
  setUserResult,
  resetGame,
} = slice.actions;

export default slice.reducer;

export const selectGame = (state: RootState) => state.game;
export const selectCurrentStep = (state: RootState) => state.game.currentStep;
export const selectCurrentQuestion = (state: RootState) => state.game.currentQuestion;
export const selectStopQuestionCountdown = (state: RootState) =>
  state.game.stopQuestionCountdown;
export const selectCounter = (state: RootState) => state.game.counter;
export const selectQuestionCountdown = (state: RootState) => state.game.questionCountdown;
export const selectQuestionData = (state: RootState) => state.game.question;
export const selectResult = (state: RootState) => state.game.result;
export const selectInitialDate = (state: RootState) => state.game.initialDate;
export const selectUserResult = (state: RootState) => state.game.userResult;
