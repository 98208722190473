/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'modal',
  initialState: { error: null, loading: null },
  reducers: {
    setLoadingModal: (state, { payload: loading }) => {
      state.loading = loading;
    },
    setErrorModal: (state, { payload: error }) => {
      state.error = error;
    },
    setLoadingClose: state => {
      state.loading = null;
    },
    setErrorClose: state => {
      state.error = null;
    },
  },
});

export const { setLoadingModal, setErrorModal, setLoadingClose, setErrorClose } =
  slice.actions;

export default slice.reducer;
