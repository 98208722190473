import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl } from 'app/api/index';
import { IResData, IResSettings } from '_common/models';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl('/settings'),
  }),
  endpoints: builder => ({
    getSettings: builder.query<IResData<IResSettings>, void>({
      query: () => ({
        url: ``,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSettingsQuery } = settingsApi;
