import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const Alert = withReactContent(Swal);

const buttonColor = '#FF6E6E';

export const ShowError = (title: string, text: string, didClose?: () => void) => {
  let txt: any = text.indexOf('message') > -1 ? JSON.parse(text) : text;
  if (txt.message) {
    if (txt.message.messages) {
      txt = txt.message.messages.join('\n');
    } else {
      txt = txt.message.error;
    }
  }

  return Alert.fire({
    icon: 'error',
    title,
    text: txt,
    didClose,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: buttonColor,
  });
};

export const ShowLoading = () => {
  return MySwal.fire({
    didOpen: () => {
      MySwal.showLoading(null);
    },
  });
};

export const HideLoading = () => {
  MySwal.close();
};
