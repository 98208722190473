/* eslint-disable no-unused-vars */
import { ReactNode, useRef } from 'react';
import cx from 'classnames';
import checkboxClasses from './Checkbox.module.scss';

export const CHECKBOX_STATES = {
  Checked: 'Checked',
  Indeterminate: 'Indeterminate',
  Empty: 'Empty',
};
interface CheckboxLabelProps {
  big?: boolean;
  round?: boolean;
  children: string;
}
interface CheckboxProps {
  id: string;
  onChange: any;
  value: string | number;
  checked: boolean;
  disabled?: boolean;
}
interface CheckboxContainerProps {
  id: string;
  label?: string;
  onChange: any;
  value: string | number;
  checked: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  customStyle?: any;
  onChangeContainer?: () => void;
  className?: string;
  children?: any;
  error: string;
  big?: boolean;
  round?: boolean;
  disabled?: boolean;
}
export function CheckboxLabel({ big, round, children }: CheckboxLabelProps) {
  return <span className={(big && round) ? checkboxClasses.bigRoundLabel : (big ? checkboxClasses.bigLabel : checkboxClasses.label)}>{children}</span>;
}
export function Checkbox({ id, onChange, value, checked, disabled }: CheckboxProps) {
  const checkboxRef = useRef();

  return (
    <input
      ref={checkboxRef as any}
      type="checkbox"
      id={id}
      className={checkboxClasses.checkbox}
      onChange={onChange}
      value={value}
      checked={checked}
      disabled={disabled}
    />
  );
}
interface InputErrorProps {
  children: string;
}
export const CheckboxError = ({ children }: InputErrorProps) => {
  const cn = cx(checkboxClasses.error);
  console.log('err:', children);

  return <span className={cn}>{children}</span>;
};
export default function CheckboxContainer({
  id,
  label,
  prefix,
  suffix,
  className,
  onChange,
  value,
  checked,
  customStyle,
  onChangeContainer,
  error,
  children,
  big,
  round,
  disabled,
}: CheckboxContainerProps) {
  const cn = cx(checkboxClasses.field, className);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <>
      <div className={cn} style={customStyle} onClick={onChangeContainer}>
        {prefix}
        <label htmlFor={id} className={checkboxClasses.container}>
          <Checkbox id={id} onChange={onChange} value={value} checked={checked} disabled={disabled} />
          <div>
            <CheckboxLabel big={big} round={round}>{label ? label : ''}</CheckboxLabel>
            <CheckboxLabel big={big} round={round}>{children && children}</CheckboxLabel>
          </div>
        </label>
        {suffix}
      </div>
      {error && <CheckboxError>{error}</CheckboxError>}
    </>
  );
}
