export const storage = localStorage;

export function getItem(key: string) {
  const item = storage.getItem(key);
  try {
    return item ? JSON.parse(item) : undefined;
  } catch (err) {
    return undefined;
  }
}

export function setItem(key: string, object: any) {
  try {
    storage.setItem(key, JSON.stringify(object));
  } catch (err) {
    console.error(err, 'Error en el guardado en localStorage');
  }
}

export const APPLICATION_KEY = 'AirEuropaFitur2024';

export const resetStorage = () => {
  setItem(APPLICATION_KEY, {});
};
