import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useAuth } from '../../hooks/useAuth';
import { ShowError } from '../../../_common/components/Alert/Alert';
import {
  IAnswerGame,
  IAnswerResult,
  IQuestion,
  IUserResult,
} from '../../../_common/models';
import { useGetGameQuery, useSendGameMutation } from '../../api/game';
import LoadingGame from '../../components/LoadingGame/LoadingGame';
import QuestionGame from '../../components/QuestionGame/QuestionGame';
import ResultGame from '../../components/ResultGame/ResultGame';

import {
  selectCounter,
  selectCurrentQuestion,
  selectCurrentStep,
  selectGame,
  selectInitialDate,
  selectQuestionData,
  selectResult,
  selectUserResult,
  setCounter,
  setCurrentQuestion,
  setCurrentStep,
  setGame,
  setInitialDate,
  setQuestion,
  setQuestionCountDown,
  setResult,
  setStopQuestionCountdown,
  setUserResult,
} from '../../slices/game';
import { selectSettings } from '../../../app/slices/settings';
import styles from './GameView.module.scss';

const GameView = () => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const game = useAppSelector(selectGame);
  const counter = useAppSelector(selectCounter);
  const currentStep = useAppSelector(selectCurrentStep);
  const currentQuestion = useAppSelector(selectCurrentQuestion);
  const currentQuestionData = useAppSelector(selectQuestionData);
  const currentResult = useAppSelector(selectResult);
  const initialDate = useAppSelector(selectInitialDate);
  const userResult = useAppSelector(selectUserResult);
  const dispatch = useAppDispatch();

  const resApiGame = useGetGameQuery();
  const [sendGame] = useSendGameMutation();
  const settingsRedux = useAppSelector(selectSettings);

  useEffect(() => {
    if (resApiGame.isSuccess) {
      dispatch(setGame(resApiGame.data.data));
    }
  }, [resApiGame]);

  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/');
      return;
    }
    if (game && currentStep === 0) {
      // TODO CAMBIAR POR COUNTER DE LARAVEL
      dispatch(setCounter(3));
    }
  }, []);

  useEffect(() => {
    if (currentStep === 0) {
      const initial = new Date().toISOString();
      dispatch(setInitialDate(initial));
      let sampleInterval = setInterval(() => {
        if ((counter as number) > 0) {
          dispatch(setCounter((counter as number) - 1));
        }
      }, 1000);
      if (counter === 0) {
        dispatch(setCurrentStep(1));
      }
      return () => {
        clearInterval(sampleInterval);
      };
    }
  }, [counter]);

  useEffect(() => {
    dispatch(setStopQuestionCountdown(false));
    if (currentStep === 1 && game.data) {
      const q = game.data[currentQuestion];
      dispatch(setQuestionCountDown(Number(settingsRedux?.game.question_countdown)));
      dispatch(setQuestion(q));
    }
    return () => {};
  }, [currentStep, currentQuestion]);

  const handleResult = (e: { question_id: number, answers_ids: number[] }) => {
    dispatch(setStopQuestionCountdown(true));

    setTimeout(async () => {
      const formatResult: IAnswerResult = { question_id: e.question_id, answer_ids: e.answers_ids};
      dispatch(setResult(formatResult));
      if (game.data && currentQuestion + 1 >= game.data.length) {
        try {
          if (initialDate) {
            const diff = moment().diff(moment(initialDate), 'milliseconds');
            const time: number = diff;
            parseFloat(time.toFixed(2));
            const num = currentStep + 1;

            const sent: IAnswerGame = { answers: [...currentResult, formatResult], time };
            const res = await sendGame(sent).unwrap();
            if (res) {
              dispatch(setUserResult(res.data));
              dispatch(setCurrentStep(num));
            }
          }
        } catch (err: any) {
          console.error('error', err);
          ShowError('Error', err.data.error);
        }
        dispatch(setStopQuestionCountdown(true));
        dispatch(setQuestionCountDown(Number(settingsRedux?.game.question_countdown)));
      } else {
        dispatch(setStopQuestionCountdown(false));
        dispatch(setQuestionCountDown(Number(settingsRedux?.game.question_countdown)));
        dispatch(setCurrentQuestion(currentQuestion + 1));
      }

      setSelectedOptions([]);
      setAnswered(false);
    }, 2000);
  };
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  const [answered, setAnswered] = useState(false);
  return (
    <div className={styles.game}>
      {currentStep === 0 && <LoadingGame counter={counter} />}
      {currentStep === 1 && currentQuestionData && (
        <QuestionGame
          question={currentQuestionData as IQuestion}
          handleClick={handleResult}
          selectedOptions={selectedOptions}
          answered={answered}
          setSelectedOptions={setSelectedOptions}
          setAnswered={setAnswered}
        />
      )}
      {currentStep === 2 && <ResultGame result={userResult as IUserResult} questionsCount={game.data?.length ?? 0} />}
    </div>
  );
};

export default GameView;
