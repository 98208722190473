import { Outlet, Route, Routes } from 'react-router-dom';
import GameLayout from '../components/GameLayout/GameLayout';
import FormView from '../views/FormView/FormView';

import GameView from '../views/Game/GameView';
import IntroView from '../views/Intro/IntroView';

const GameRoutes = () => {
  return (
    <GameLayout>
      <Outlet />
      <Routes>
        <Route index path="/" element={<FormView />} />
        <Route path="/intro" element={<IntroView />} />
        <Route path="/play" element={<GameView />} />
      </Routes>
    </GameLayout>
  );
};

export default GameRoutes;
