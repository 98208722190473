import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkTo from '../../../_common/components/LinkTo/LinkTo';
import Title from '../../../_common/components/Title/Title';
import { useAuth } from '../../hooks/useAuth';
import styles from './IntroView.module.scss';

const IntroView = () => {
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/');
      return;
    }
  }, []);

  return (
    <div className={styles.intro}>
      <div className={styles.text}>
        <Title type="h3" color="ae-coral">
          Mediante esta prueba
        </Title>
        <Title type="p" color="white">
          Pondremos a prueba tanto lo que sabes de Air Europa como de Air Europa Suma y la
          velocidad a la que contestas.
        </Title>
        <Title type="p" color="white">
          En la pantalla irán apareciendo preguntas.
        </Title>
        <Title type="p" color="white">
          Pulsa sobre la respuesta o respuestas correctas.
        </Title>
        <Title type="p" color="white">
          Tendremos en cuenta tus respuestas correctas y la velocidad de reacción.
        </Title>
      </div>

      <LinkTo
        button
        href="/game/play"
        variant="raised"
        fullWidth
        className={styles.button}
        color="coral"
      >
        Continuar
      </LinkTo>
    </div>
  );
};

export default IntroView;
