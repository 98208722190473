import React, { ReactNode } from 'react';
import cx from 'classnames';
import buttonClasses from './Button.module.scss';

export type ButtonColor = 'coral' | 'white' | 'green' | 'red';

export type ButtonType = 'button' | 'submit';

export type ButtonVariant = 'normal' | 'raised' | 'outline' | 'flat';

export interface ButtonProps {
  className?: string;
  variant?: ButtonVariant;
  type?: ButtonType;
  color?: ButtonColor;
  children?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  fullWidth?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  minimal?: boolean;
  pointer?: boolean;
  disabled?: boolean;
}
const Button = ({
  variant = 'normal',
  color = 'coral',
  type = 'button',
  minimal = false,
  pointer = false,
  className,
  fullWidth,
  onClick,
  children,
  prefix,
  suffix,
  disabled,
}: ButtonProps) => {
  const cs = cx(
    minimal ? buttonClasses.minimal : buttonClasses.button,
    {
      [buttonClasses[`variant-${variant}`]]: variant !== undefined,
      [buttonClasses[`color-${color}`]]: color !== undefined,
      [buttonClasses[`full-width`]]: fullWidth,
      [buttonClasses.pointer]: pointer,
    },
    disabled && 'disabled',
    className
  );

  return (
    <button type={type} onClick={onClick} className={cs} disabled={disabled}>
      {prefix}
      {children && <span>{children}</span>}
      {suffix}
    </button>
  );
};

export default Button;
