import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../_common/components/Button/Button";
import Title from "../../../_common/components/Title/Title";
import { IAnswer, IQuestion } from "../../../_common/models";
import {
  selectQuestionCountdown,
  selectStopQuestionCountdown,
  setQuestionCountDown,
  setStopQuestionCountdown,
} from "../../slices/game";

import styles from "./QuestionGame.module.scss";
import CheckboxContainer from "_common/components/Checkbox/Checkbox";
interface QuestionGameProps {
  question: IQuestion;
  handleClick: any;
  selectedOptions: number[];
  answered: boolean;
  setSelectedOptions: React.Dispatch<React.SetStateAction<number[]>>;
  setAnswered: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionGame = ({
  question,
  handleClick,
  selectedOptions,
  answered,
  setSelectedOptions,
  setAnswered,
}: QuestionGameProps) => {
  const dispatch = useAppDispatch();
  const questionCountdown = useAppSelector(selectQuestionCountdown);
  const stopQuestionCountdown = useAppSelector(selectStopQuestionCountdown);

  function handleAnswerClick(id: number, value: boolean) {
    setSelectedOptions((options) => {
      if (value) {
        if (question.nature === "multiple") {
          return options.concat(id);
        } else {
          return options.filter((o) => false).concat(id);
        }
      } else {
        return options.filter((o) => o != id);
      }
    });
  }

  function handleAnswerButton() {
    setAnswered(true);
  }

  function allCorrect() {
    let correctArePresent = true;
    let noAdditionalAnswers = true;
    let correctAnswers = question.answers.filter((a) => a.correct);
    correctAnswers.forEach((a) => {
      if (!selectedOptions.includes(a.id)) {
        correctArePresent = false;
        return;
      }
    });
    noAdditionalAnswers = correctAnswers.length === selectedOptions.length;
    return correctArePresent && noAdditionalAnswers;
  }

  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if ((questionCountdown as number) > 0 && !stopQuestionCountdown) {
        dispatch(setQuestionCountDown((questionCountdown as number) - 1));
      }
    }, 1000);

    if (questionCountdown === 0 && !stopQuestionCountdown) {
      handleAnswerButton();
      handleClick({ question_id: question.id, answers_ids: selectedOptions });
      clearInterval(sampleInterval);
      dispatch(setStopQuestionCountdown(true));
      return;
    }
    if (stopQuestionCountdown) {
      clearInterval(sampleInterval);
    }
    return () => {
      clearInterval(sampleInterval);
    };
  }, [questionCountdown, stopQuestionCountdown]);

  return (
    <>
      <div className={styles.containerCount}>
        <Title type="h1" color="ae-coral" className={styles.count}>
          {`${questionCountdown}`}
        </Title>
      </div>
      <div className={styles.question}>
        <Title type="h3" color="white">
          {question.name}
        </Title>
        {question.nature === "map" ? (
          <div>
            {/* Aquí va un componente de mapas al que le pasaremos la question (para que pinte las respuestas según su alias : {question.alias}) y el evento de handleAnswerClick */}
          </div>
        ) : (
          question.answers.map((el: IAnswer) => {
            return (
              <div className={styles.answer} key={`${question.id}-${el.id}`}>
                <CheckboxContainer
                  id={`${question.id}-${el.id}`}
                  label={el.name}
                  value={el.id}
                  checked={selectedOptions.includes(el.id)}
                  onChange={(event: any) => {
                    handleAnswerClick(el.id, event.target.checked);
                  }}
                  error={""}
                  big={true}
                  round={question.nature === "single"}
                ></CheckboxContainer>
              </div>
            );
          })
        )}
        <Button
          variant="outline"
          color="white"
          onClick={() => {
            handleAnswerButton();
            handleClick({
              question_id: question.id,
              answers_ids: selectedOptions,
            });
          }}
          disabled={selectedOptions.length === 0}
          fullWidth
          className={
            answered
              ? allCorrect()
                ? styles.btnCorrect
                : styles.btnIncorrect
              : ""
          }
        >
          Continuar
        </Button>
      </div>
    </>
  );
};

export default QuestionGame;
