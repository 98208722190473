/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IResSettings } from '../../_common/models';
import { RootState } from './store';
interface SettingsState {
  settings: IResSettings | undefined;
}
const initialState: SettingsState = {
  settings: undefined,
};
const slice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setSettings: (state, { payload: obj }) => {
      state.settings = obj;
    },
    resetSettings: () => {
      return initialState;
    },
  },
});

export const { setSettings, resetSettings } = slice.actions;

export default slice.reducer;

export const selectSettings = (state: RootState) => {
  return state.settings.settings;
};
