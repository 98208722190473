import { useState } from 'react';

export const useInputErrors = () => {
  const [errors, setErrors] = useState<any>({});

  const updateErrors = (errorsForm: any) => {
    setErrors((state: any) => ({
      ...state,
      ...errorsForm,
    }));

    return false;
  };

  const resetErrors = () => {
    setErrors({});
    return true;
  };

  return {
    errors,
    setErrors,
    resetErrors,
    updateErrors,
  };
};
