/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/slices/store';
import { resetStorage } from '../../app/utils/cache';
import { IUser } from '../../_common/models';
type AuthState = { user: IUser | undefined; token: string | undefined };

const initialState: AuthState = { user: undefined, token: undefined };
const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      state.user = user;
      state.token = token;
    },
    logout: () => {
      resetStorage();
      return initialState;
    },
    setCurrentUser: (state, { payload: user }) => {
      state.user = user;
    },
  },
});

export const { setCredentials, logout, setCurrentUser } = slice.actions;

export default slice.reducer;

export const selectCurrentAuth = (state: RootState) => state.auth;
