import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useInputErrors } from '../../../app/hooks/useInputErrors';
import { selectSettings } from '../../../app/slices/settings';
import { isEmpty, validateSumaNumber, isFalse } from '../../../app/utils/validations';
import { SIGNUP_AE_SUMA_URL } from '../../../app/utils/variables';
import { ShowError } from '../../../_common/components/Alert/Alert';
import Button from '../../../_common/components/Button/Button';
import CheckboxContainer from '../../../_common/components/Checkbox/Checkbox';
import Input, { InputContainer } from '../../../_common/components/Input/Input';
import LinkTo from '../../../_common/components/LinkTo/LinkTo';
import { useLoginMutation } from '../../api/auth';
import { setCredentials } from '../../slices/auth';
import { resetGame } from '../../slices/game';
import { setCurrentStep } from '../../slices/game';

import styles from './FormView.module.scss';
const FormView = () => {
  const settingsRedux = useAppSelector(selectSettings);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [sumaNum, setSumaNum] = useState('');
  const [privacy, setPrivacy] = useState(false);

  let navigate = useNavigate();
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();
  const { errors, resetErrors, setErrors, updateErrors } = useInputErrors();

  const handleSubmitLogin = async () => {
    if (onValidateLogin()) {
      const obj = { name, surname, suma: sumaNum, privacy };
      try {
        dispatch(resetGame());
        const { data } = await login(obj).unwrap();
        if (data) {
          dispatch(setCredentials(data));
        }
        dispatch(setCurrentStep(0));
        navigate('intro');
      } catch (error: any) {
        console.error(error);
        ShowError('Error', error.data.error);
      }
    }
  };

  const onValidateLogin = () => {
    let isValid = resetErrors();
    setErrors({});

    if (isEmpty(name)) {
      isValid = updateErrors({
        name: 'El nombre es obligatorio',
      });
    }

    if (isEmpty(surname)) {
      isValid = updateErrors({
        surname: 'Los apellidos son obligatorios',
      });
    }
    if (isEmpty(sumaNum)) {
      isValid = updateErrors({
        sumaNum: 'El número Suma es obligatorio',
      });
    } else if (!validateSumaNumber(sumaNum)) {
      isValid = updateErrors({
        sumaNum: 'El número Suma debe tener 10 carácteres',
      });
    }
    if (isFalse(privacy)) {
      isValid = updateErrors({
        privacy: 'Debes aceptar la política de privacidad',
      });
    }

    return isValid;
  };

  return (
    <>
      <div className={styles.form}>
        <>
          <InputContainer name="name" error={errors.name}>
            <Input
              name="name"
              fullWidth
              type="text"
              placeholder={'Introduce tu nombre'}
              onChange={setName}
              value={name}
            />
          </InputContainer>
          <InputContainer name="surname" error={errors.surname}>
            <Input
              name="surname"
              fullWidth
              type="text"
              placeholder={'Introduce tus apellidos'}
              onChange={setSurname}
              value={surname}
            />
          </InputContainer>

          <InputContainer name="suma" error={errors.sumaNum}>
            <Input
              name="suma"
              fullWidth
              type="number"
              placeholder={'Introduce tu número Air Europa Suma'}
              onChange={setSumaNum}
              pattern="[0-9]*"
              value={sumaNum}
            />
          </InputContainer>
        </>
        <CheckboxContainer
          id="privacy"
          value="privacy"
          label="Acepto la "
          checked={privacy}
          onChange={(event: any) => {
            setPrivacy(event.target.checked);
          }}
          error={errors.privacy}
        >
          <LinkTo
            isExternalLink
            href={settingsRedux?.game.privacy_url || SIGNUP_AE_SUMA_URL}
            color="white"
          >
            política de privacidad
          </LinkTo>
        </CheckboxContainer>
        <Button
          variant="raised"
          fullWidth
          className={styles.button}
          color="coral"
          onClick={handleSubmitLogin}
        >
          Continuar
        </Button>
        <LinkTo
          isExternalLink
          href={settingsRedux?.game.register_link || SIGNUP_AE_SUMA_URL}
          color="white"
        >
          Darse de alta en Air Europa Suma
        </LinkTo>
      </div>
    </>
  );
};

export default FormView;
