import { useNavigate } from 'react-router-dom';

import LinkTo from '../../_common/components/LinkTo/LinkTo';
import Title from '../../_common/components/Title/Title';
import styles from './HomeView.module.scss';

const HomeView = () => {
  
  return (
    <div className={styles.home}>
      <div className={styles.intro}>
        <Title type="h3" color="ae-coral">
          ¿Cuánto sabes de?
        </Title>
        <div className={styles.logo}>
          <img
            alt="logo-air-europa"
            src="/img/logo_aireuropa.svg"
            style={{ width: '100%' }}
          />
        </div>
        <LinkTo
          button
          href="/game"
          variant="raised"
          fullWidth
          className={styles.button}
          color="coral"
        >
          Empezar
        </LinkTo>
      </div>
    </div>
  );
};

export default HomeView;
