import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl, tokenInterceptor } from 'app/api/index';
import { IAnswerGame, IQuestion, IResData } from '../../_common/models';

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl('/game'),
    prepareHeaders: tokenInterceptor,
  }),
  endpoints: builder => ({
    getGame: builder.query<IResData<IQuestion[]>, void>({
      query: () => ({
        method: 'GET',
        url: '',
      }),
    }),
    sendGame: builder.mutation({
      query: (obj: IAnswerGame) => ({
        url: '',
        method: 'POST',
        body: obj,
      }),
    }),
  }),
});

export const { useGetGameQuery, useSendGameMutation } = gameApi;
