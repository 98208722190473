/* eslint-disable no-console */
import { configureStore } from '@reduxjs/toolkit';
import { settingsApi } from '../api/settings';

import modalReducer from './modals';
import settingsReducer from './settings';
import authReducer from '../../game/slices/auth';
import gameReducer from '../../game/slices/game';

import { APPLICATION_KEY, getItem, setItem } from '../utils/cache';
import { authApi } from '../../game/api/auth';
import { gameApi } from '../../game/api/game';

const includeKeys = ['auth', 'game', 'settings'];

// MIDDLEWARE Storage
const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    const state = getState();

    const keys = Object.keys(state);
    const savedState: any = {};
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (includeKeys.indexOf(key) > -1) {
        savedState[key] = state[key];
      }
    }
    // console.log('UPDATE STATE', action, result, savedState);
    setItem(APPLICATION_KEY, savedState);
    return result;
  };
};

const reHydrateStore = () => {
  const store = getItem(APPLICATION_KEY);
  // console.log('REHYDRATE STATE', store);
  const newStore = store ? getItem(APPLICATION_KEY) : {};

  return { ...newStore, ...{ modal: { error: null, loading: null } } };
};

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    modal: modalReducer,
    auth: authReducer,
    game: gameReducer,
    settings: settingsReducer,
  },
  preloadedState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      localStorageMiddleware,
      authApi.middleware,
      settingsApi.middleware,
      gameApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
