/* eslint-disable prefer-spread */
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getBaseUrl = (path: string) => {
  return baseUrl + path;
};

export const getApiUrl = (path: string) => {
  return getBaseUrl(`api${path}`);
};

export const tokenInterceptor = (headers: any, { getState }: any) => {
  const state = getState();
  const token = state?.auth.token || '';
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
    headers.set('Accept', 'application/json');
  }
  return headers;
};
