const SUMA_REGEX = /^[1-9][0-9]{9}$/;
export const isEmpty = (val: string | null | undefined | Array<any> | number) => {
  return (
    val === '' ||
    val === null ||
    val === undefined ||
    (Array.isArray(val) && val.length === 0) ||
    val == 0 ||
    !val
  );
};
export const isFalse = (val: boolean) => val === false;

export const validateSumaNumber = (value: string): boolean => {
  return SUMA_REGEX.test(value);
};
