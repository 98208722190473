import { useEffect } from 'react';
import moment from 'moment';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../game/hooks/useAuth';
import GameRoutes from '../../game/routes';
import { logout } from '../../game/slices/auth';
import {
  resetGame,
  selectInitialDate,
  setQuestionCountDown,
} from '../../game/slices/game';
import { useGetSettingsQuery } from '../api/settings';
import MainLayout from '../components/MainLayout/MainLayout';
import { useAppDispatch, useAppSelector } from '../hooks';
import { isEmpty } from '../utils/validations';
import HomeView from '../views/HomeView';
import { resetSettings, selectSettings, setSettings } from '../slices/settings';

const AppRoutes = () => {
  const { token } = useAuth();

  const settings = useGetSettingsQuery();

  const dispatch = useAppDispatch();
  const initialDateRedux = useAppSelector(selectInitialDate);
  const settingsRedux = useAppSelector(selectSettings);

  useEffect(() => {
    if (!settingsRedux) {
      if (settings.isSuccess) {
        dispatch(setSettings(settings.data.data));

        dispatch(
          setQuestionCountDown(Number(settings.data.data.game.question_countdown))
        );

        if (initialDateRedux) {
          const minutesInitialGameAgo = moment().diff(
            moment(initialDateRedux),
            'minutes'
          );
          if (
            !isEmpty(token) &&
            minutesInitialGameAgo > settings.data.data.game.localstorage_ttl
          ) {
            dispatch(resetGame());
            dispatch(logout());
            dispatch(resetSettings());
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [settings]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          index
          element={<Navigate to={token ? 'game/game' : '/'} replace />}
        />
        <Route path="/*" element={<MainLayout />}>
          <Route index element={<HomeView />} />
          <Route path="game/*" element={<GameRoutes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
